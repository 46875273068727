<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/QAList"
            >问答列表 /
          </router-link>
          <span class="crumbs_item crumbs_last">{{
            editObj ? "编辑" : "新建"
          }}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name all_required">提问：</span>
        <div class="right_Div">
          <a-input class="all_input" placeholder="请输入标题" v-model="title" />
        </div>
      </div>
      <div class="L_R_inner">
        <div class="Up_Down_inner">
          <span class="all_left_name all_required">回答：</span>
          <UE
            editorId="introduceEditor1"
            :initContent="ueInitText"
            @input="getContent"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">所属分类：</span>
        <div class="right_Div">
          <a-select
            class="search-input"
            placeholder="请选择所分类"
            v-model="qaTypeId"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option
              :value="item.answerId"
              v-for="item in sortList"
              :key="item.answerId"
            >
              {{ item.categoryName }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>

    <div class="button">
      <a-button type="primary" class="btn" :loading="PreventLoad" @click="addQuestion">确认</a-button>
      <a-button class="all_boder_btn btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
export default {
  // 可用组件的哈希表
  components: { UE }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      ueInitText: "", // 内容
      title: "", // 标题
      sortList: [], // 分类列表
      qaTypeId: undefined, // 分类id
      editObj: null,
      PreventLoad: false
    };
  }, // 事件处理器
  methods: {
    // 富文本内容有变化时触发
    getContent(e) {
      this.$set(this, "ueInitText", e);
    },
    // 新建问答
    addQuestion() {
      if (!this.title) {
        return this.$message.warning("请输入提问");
      } else if (!this.ueInitText) {
        return this.$message.warning("请输入回答");
      } else if (!this.qaTypeId) {
        return this.$message.warning("请选择分类");
      }
      this.PreventLoad = true;
      this.$ajax({
        url: this.editObj
          ? "/hxclass-management/information/answer"
          : "/hxclass-management/information/answer",
        method: this.editObj ? "put" : "post",
        params: this.editObj
          ? {
              content: this.ueInitText, //	内容
              qaTypeId: this.qaTypeId, //	分类id
              title: this.title, //	标题
              qaId: this.editObj.qaId,
              sort: this.editObj.sort,
            }
          : {
              content: this.ueInitText, //	内容
              qaTypeId: this.qaTypeId, //	分类id
              title: this.title, //	标题
            },
      }).then((res) => {
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.$router.go(-1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取分类列表
    getSortList() {
      this.$ajax({
        url: "/hxclass-management/information/answer/category/list",
        method: "get",
        params: {
          page:1,
          size:100
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.sortList = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getSortList(); // 获取分类列表
    if (this.$route.params.qaId) {
      this.editObj = this.$route.params;
      this.title = this.$route.params.title;
      this.ueInitText = this.$route.params.content;
      this.qaTypeId = this.$route.params.qaTypeId;
    }
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .all_content_box {
    .L_R_inner {
      margin: 24px;
      .all_left_name {
        width: 100px;
        text-align: right;
      }

      .vue-ueditor-wrap[data-v-1e1388a0] {
        margin-left: 120px;
        width: 600px;
      }

      .right_Div {
        .ant-select,
        .all_input {
          width: 337px;
        }
        /deep/.ant-input {
          height: 37px;
        }
      }
    }
  }

  .button {
    .btn {
      margin-left: 20px;
    }
  }
}
</style>
